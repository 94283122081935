<template>
  <a-modal :title='title' :width='800' :visible='visible' :confirmLoading='confirmLoading' @ok='handleOk' @cancel='close'
           cancelText='关闭'>

    <a-spin :spinning='confirmLoading'>
      <a-form>
        <a-card :bordered="false">
          <detail-list title="提现信息" :col="2">
            <detail-list-item term="提现金额(元)">{{ formData.money }}</detail-list-item>
            <detail-list-item term="提现手续费(元)">{{ formData.serviceChargeNum }}</detail-list-item>
            <detail-list-item term="应转账金额(元)">{{ formData.realMoney }}</detail-list-item>
            <detail-list-item term="提现状态">{{ formData.status | statusFilter }}</detail-list-item>
            <detail-list-item term="提现申请时间">{{ formData.createTime }}</detail-list-item>
          </detail-list>
          <a-divider style="margin-bottom: 32px"/>
          <detail-list title="收款账户信息" :col="2">
            <detail-list-item term="账户类型">{{ formData.withdrawType | typeFilter }}</detail-list-item>
            <detail-list-item term="账户姓名">{{ formData.accountName }}</detail-list-item>
            <detail-list-item term="账户号">{{ formData.accountNum }}</detail-list-item>
            <detail-list-item term="收款二维码"><img-preview   :src=" formData.qrCode" :size="60"  style="margin-right: 8px;" /></detail-list-item>
          </detail-list>

          <template v-if="formData.withdrawType==3">
            <a-divider style="margin-bottom: 32px"/>
            <detail-list title="收款银行信息" :col="2">
              <detail-list-item term="银行名称">{{ formData.bankName }}</detail-list-item>
              <detail-list-item term="开户行地址">{{ formData.bankAdress }}</detail-list-item>
            </detail-list>

          </template>
        </a-card>
        <a-form-item label="审核结果" :labelCol="labelCol" :wrapperCol="wrapperCol">
<!--          <api-select placeholder='请选择审核结果' v-model="formData.status" :option="statusOptions" />-->
          <a-select v-model="formData.status" :disabled='isReadOnly' placeholder="请选择">
            <a-select-option :value="0">待审核</a-select-option>
            <a-select-option :value="1">审核驳回</a-select-option>
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">取消</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-show="formData.status == 1" label="审核结果" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea :rows='3' :readOnly="isReadOnly" placeholder='请输入拒绝原因(80字以内)' v-model="formData.auditOpinion"  :maxlength="80"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import ApiSelect from '@/components/crud/form/ApiSelect.vue'
import { approveWithDraw } from '@/api/user/withdraw'
import DetailList from '@/components/crud/DetailList'
import ImgPreview from '@/components/crud/table/ImgPreview.vue'
const DetailListItem = DetailList.Item;
export default {
  name: 'CrudForm',
  components: {
    ImgPreview,
    ApiSelect,
    DetailList,
    DetailListItem,
  },
  props: {
    statusOptions: {
      type: Array,
      default() {
        return [{ id: '2', name: '审核通过' }, { id: '1', name: '审核驳回' },{ id: '0', name: '待审核' }];
      }
    }
  },
  data() {
    return {
      title:"审核",
      formData: {
        // id: '',
        // userId: null,
        // status: null,
        // failReason:null
      },           // 表单数据
      visible: false,         // 弹窗显示控制
      confirmLoading: false,  // 提交后加载, 防止短时间内重复提交
      isReadOnly: false,
      labelCol: { xs: { span: 24 }, sm: { span: 5 } },    // 表单项标签宽度配置
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, // 表单项内容宽度配置
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        '0': '待审核',
        '1': '审核驳回',
        '2': '审核通过'
      };
      return statusMap[status]
    },
    typeFilter(status) {
      const statusMap = {
        1: '支付宝',
        2: '微信',
        3: '银行卡'
      };
      return statusMap[status]
    },

  },
  methods: {
    /**
     * 外部方法, 外部调用此方法 打开编辑表单
     * @param {*} record 数据
     */
    audit(record,isReadOnly) {
      // this.formData.id = record.id;
      // this.formData.userId = record.userId;
      // this.formData.status = null;
      // this.formData.failReason = '';
      this.formData = record
      this.confirmLoading = false;
      if(!isReadOnly){
        this.isShow = false;
        this.isReadOnly = true;
        this.title = "详情"
      }
      this.visible = true;
    },

    /**
     * 关闭表单, 触发 close 事件
     */
    close() {
      this.$emit('close')
      this.visible = false
      this.isReadOnly = false;
    },
    /**
     * 提交
     */
    handleOk() {
      if(!this.isReadOnly){
        this.confirmLoading = true;
        if (this.formData.status != '1' && this.formData.status != '2') {
          this.$message.warn("请选择审核结果");
          this.confirmLoading = false;
          return;
        }
        if (this.formData.status == '1' && !this.formData.auditOpinion) {
          this.$message.warn("请输入拒绝原因");
          this.confirmLoading = false;
          return;
        }
        approveWithDraw(this.formData).then(res => {
          if (res.success) {
            this.$message.success(res.message);
            this.close()
            this.$emit('ok');
          } else {
            this.$message.warning(res.message)
          }

        }).finally(() => {
          this.submitLoading = false
        })
      }else{
        this.close()
        this.$emit('ok');
      }

    },
  }
}
</script>

<style lang='less' scoped></style>